import { useEffect } from "react";
import { useInView } from "react-intersection-observer";
import { motion, useAnimation } from "framer-motion";
import { Message, NeumorphismFlatButton } from "../components";
import "../../styles/home/Why.css"; // Import CSS file for styling
import appStoreLogo from "../../assets/images/Download_on_the_App_Store_Badge_US-UK_RGB_blk_092917.svg";
import googlePlayLogo from "../../assets/images/GetItOnGooglePlay_Badge_Web_color_English.png";

const messages = [
  "Hey there! Great picture with the baby panda! Did you get to hold one?",
  "Haha, thanks, no holding. Pandas are adorable though, right?",
  "Absolutely! So, what brings you here?",
  "Looking to meet new people, maybe someone who also loves giant mammals. You?",
  "Haha, good start! Hoping to find someone interesting to chat with, maybe grab coffee.",
  "Coffee sounds great! Free this week?",
  "Sure, how about Thursday evening?",
  "Perfect! Where to meet? So many coffee shops...",
  "Let's skip the trendy coffee shops. Any ideas for something casual?",
  "Bookstore downtown with the cafe in the back?",
  "Outdoorsy maybe? Park by the river?",
  "Haha, maybe reschedule the 'coffee date' when we figure out where to go!",
];

const title = [
  "VibeCheck™ Compatibility",
  "First Date Focus",
  "Meaningful Connections or Fun Experiences",
  "VibeConnect™: Support Local Businesses",
];

const content = [
  "Ditch the endless matching! We match you based on shared interests and date ideas, not just profiles.",
  "Skip the awkward pre-date small talk. Align on expectations with built-in activity suggestions.",
  "Every date is a win. Spark something special or add a new adventure to your story.",
  "Discover exciting events and places, and help businesses thrive with our curated venue partnerships.",
];

const titleVariant = {
  hidden: { opacity: 0 },
  visible: { opacity: 1, transition: { duration: 1 } },
};

const pointVariant = {
  visible: {
    opacity: 1,
    scale: [1, 1.2, 1], // Scale up and down in a loop
    transition: { duration: 2, repeat: Infinity, ease: "easeInOut" }, // Continuous pulsating animation
  },
};

const Why = () => {
  const control = useAnimation();
  const [titleRef, titleInView] = useInView({ triggerOnce: true });
  const [pointRef, pointInView] = useInView({ triggerOnce: true });

  useEffect(() => {
    if (titleInView) {
      control.start("visible");
    }
  }, [control, titleInView]);

  useEffect(() => {
    if (pointInView) {
      control.start("visible");
    }
  }, [control, pointInView]);

  return (
    <>
      <motion.div
        ref={titleRef}
        variants={titleVariant}
        initial="hidden"
        animate={control}
      >
        <h1
          className="center-text"
          style={{ margin: "-8vh 2vw 8vh 2vw", paddingTop: "8vh" }}
        >
          Imagine
        </h1>
      </motion.div>

      {messages.map((message, index) => (
        <Message key={index} message={message} isFromMe={index % 2 === 0} />
      ))}

      <h1 className="center-text">YOU GET THE</h1>

      <motion.div
        className="center-text"
        ref={pointRef}
        variants={pointVariant}
        initial="hidden"
        animate={control}
      >
        <h1 className="point">•</h1>
      </motion.div>

      <div className="why-point">
        {title.map((title, index) => (
          <NeumorphismFlatButton
            key={index}
            title={title}
            content={content[index]}
          />
        ))}
      </div>

      <h1
        className="center-text"
        style={{ fontSize: "3.2vh", margin: "0 2vw 1vh 2vw" }}
      >
        Love is just a tap away!
      </h1>
      <h1
        className="center-text"
        style={{ fontSize: "3.2vh", margin: "0 2vw" }}
      >
        One Date, with its innovative VibeCheck™. Be the first to experience it!
      </h1>
      <div className="download-logo">
        <a
          target="_blank"
          rel="noreferrer"
          href="https://apps.apple.com/sg/app/one-date-activity-based-dates/id6503325960"
        >
          <img
            src={appStoreLogo}
            alt="Download on the App Store"
            width="auto"
            height="80"
          />
        </a>
        <a
          target="_blank"
          rel="noreferrer"
          href="https://play.google.com/store/apps/details?id=com.vibeinc.onedate"
        >
          <img
            src={googlePlayLogo}
            alt="Get it on Google Play"
            width="auto"
            height="80"
          />
        </a>
      </div>
    </>
  );
};

export default Why;
